import * as React from "react";
import Layout from "../components/Layout/Layout";
import { HomeSlider, ExclusiveProducts, TextSplit } from "components";
import { useStaticQuery, graphql } from "gatsby";
import { Wrapper } from "../components/globalElements";

const Home = () => {
	const {
		datoCmsHome,
		allDatoCmsHomeHeroSlider,
		allDatoCmsExclusiveProduct
	} = useStaticQuery(query);

	const exclusiveData = allDatoCmsExclusiveProduct.edges.map(
		({ node }) => node
	);
	const sliderData = allDatoCmsHomeHeroSlider.edges.map(({ node }) => node);
	const content = datoCmsHome.heroText.value.document.children.map(
		(el) => el.children[0].value
	);

	return (
		<Layout title="Australian Luxury Tiles" fullScreen={true}>
			<HomeSlider data={sliderData} />
			<Wrapper style={{ marginBottom: "5rem" }}>
				<TextSplit>
					<h2>{datoCmsHome.heroTagline}</h2>
					<div className="content-block">
						{content.map(
							(text, i) =>
								text.length > 0 && (
									<p className="content" key={i}>
										{text}
									</p>
								)
						)}
					</div>
				</TextSplit>
				<ExclusiveProducts exclusiveProducts={exclusiveData} />
			</Wrapper>
		</Layout>
	);
};

export const query = graphql`
	{
		datoCmsHome {
			heroTagline
			heroText {
				value
			}
		}

		allDatoCmsHomeHeroSlider {
			edges {
				node {
					id
					title
					copy
					image {
						alt
						url
						gatsbyImageData
					}
					button1Path
					button1Title
					videoLink
				}
			}
		}

		allDatoCmsExclusiveProduct {
			edges {
				node {
					description
					id
					image {
						fluid(maxWidth: 3000) {
							src
						}
						gatsbyImageData
						alt
					}
					linkTitle
					linkPath
					videoLink
					title
				}
			}
		}
	}
`;

export default Home;
